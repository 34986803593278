import * as Auth from '@aws-amplify/auth';
const awsmobile = require('./aws-exports');

const queries = require('./graphql/queries')
const mutations = require('./graphql/mutations')

const MUTATIONS = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete'
}

const paginationData = JSON.parse(sessionStorage.getItem('paginationData') || '{}')

const url = awsmobile.default.aws_appsync_graphqlEndpoint;

const fetchWithToken = async (method, query, variables) => {
    const session = await Auth.fetchAuthSession();
    const token = session.tokens.accessToken.toString();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
        },
        body: JSON.stringify({ query, variables }),
    });
    const jsonResponse = await response.json();
    const { data, errors } = jsonResponse
    if (errors?.length) {
        console.log('DataProvider, Error:', errors, query, variables)
        throw new Error(errors)
    }
    if (!data) {
        if (jsonResponse?.message?.match(/Token has expired/)) {
            location = '/'
        } else {
            console.error(method, jsonResponse)
        }
    }
    return data;
};

const cleanVariablesInput = (data) => {
    delete data?.createdAt
    delete data?.updatedAt
}

const getResourceListName = (resource) => {
    return `${resource.charAt(0).toUpperCase()}${resource.slice(1)}`;
};

const getResourceName = (resource) =>
    getResourceListName(resource)?.slice(0, resource.length - 1)

const getListQuery = (resource) => {
    const queryName = `list${getResourceListName(resource)}`
    const query = queries[queryName]
    if (!query) {
        throw new Error(`Query not found: ${queryName}`)
    }
    return {
        queryName,
        query
    }
};

const getOneQuery = (resource) => {
    const queryName = `get${getResourceName(resource)}`
    const query = queries[queryName]
    if (!query) {
        throw new Error(`Query not found: ${queryName}`)
    }
    return {
        queryName,
        query
    }
};

const getMutation = (resource, mutationType) => {
    const mutationName = `${mutationType}${getResourceName(resource)}`
    const mutation = mutations[mutationName]
    if (!mutation) {
        throw new Error(`Mutation not found: ${mutationName}`)
    }
    return {
        mutationName,
        mutation
    }
};

const getOne = async (resource, params) => {
    try {
        if (Array.isArray(params?.id)) {
            return await getMany(resource, { ids: params.id })
        }
        const queryData = getOneQuery(resource, params);
        const variables = { id: params.id };
        const data = await fetchWithToken('POST', queryData.query, variables);
        return { data: data[queryData.queryName] };
    } catch (error) {
        console.error(error)
        throw new Error('Sorry, could not read data.')
    }
}

const getList = async (resource, params) => {
    try {
        const postFilter = params?.filter?.postFilter
        delete params?.filter?.postFilter

        const { skipPaginationData } = params
        delete params.skipPaginationData
        const { sort, meta } = params || {}
        const currentPageNumber = parseInt(params?.pagination?.page) || 1
        if (!skipPaginationData && !paginationData[resource]) {
            paginationData[resource] = {}
        }
        let queryData = null
        if (typeof (params?.filter ?? 0) === 'object' && Object.keys(params.filter)[0]) {
            const filterQueryName = Object.keys(params.filter)[0]
            const filterQuery = queries[filterQueryName]

            if (filterQuery) {
                queryData = {
                    queryName: filterQueryName,
                    query: filterQuery
                }
                params = {
                    ...params.filter[filterQueryName],
                    pagination: params.pagination
                }
            } else if (filterQueryName === 'q') {
                params.filter = {
                    name: {
                        contains: params.filter[filterQueryName]
                    }
                }
            }
        }
        if (!queryData) {
            queryData = getListQuery(resource, params);
        }

        const filterObject = typeof params?.filter === 'string' ? { filter: params?.filter } : { filter: { ...(params?.filter ?? {}), ...(meta ?? {}) } }
        const { page, perPage } = params?.pagination || {}
        const nextTokenInput = skipPaginationData ? null : paginationData[resource][currentPageNumber]?.startingToken
        const variables = {
            limit: perPage,
            nextToken: (!nextTokenInput ? (page - 1) * perPage : nextTokenInput) || null,
            filter: typeof filterObject.filter === 'string' ? filterObject.filter : (Object.keys(filterObject.filter).length ? params.filterAsJson ? filterObject.filter : JSON.stringify(filterObject.filter) : undefined),
            sort
        }
        const data = await fetchWithToken('POST', queryData.query, JSON.parse(JSON.stringify(variables, null, 0)));
        const { items, total, nextToken } = data?.[queryData?.queryName] || {}
        const nextTokenObject = handleNextToken(nextToken)
        const nextTokenValue = nextTokenObject && isNaN(JSON.parse(nextTokenObject)) && nextTokenObject !== '"{}"' && nextTokenObject !== '""' ? nextTokenObject : null

        if (!skipPaginationData) {
            paginationData[resource][currentPageNumber] = { startingToken: paginationData[resource][currentPageNumber - 1]?.nextTokenValue, nextTokenValue }
            paginationData[resource][currentPageNumber + 1] = { startingToken: nextTokenValue }
        }
        return {
            data: (postFilter ? postFilter(items) : items) ?? [],
            nextToken: nextTokenValue,
            total: Math.max(nextTokenValue ? (currentPageNumber * (params?.pagination?.perPage || 1)) + 1 : (((currentPageNumber || 1) - 1) * (params?.pagination?.perPage || 1)) + (items || []).length, total || 0)
        };
    } catch (error) {
        console.error(error)
        throw new Error('Sorry, could not read data.')
    }
}

const handleNextToken = (nextToken) => {
    if (nextToken && isNaN(nextToken)) {
        try {
            return JSON.stringify(JSON.parse(nextToken))
        } catch {

        }
        const nextTokenObject = {}
        nextToken.match(/([^{}\s]+\=[^\s{},]+)/g)?.map(item => {
            const keyValue = item.split('=')
            nextTokenObject[`${keyValue[0]}`] = isNaN(keyValue[1]) ? (keyValue[1] == 'true' || keyValue[1] == 'false' ? (keyValue[1] == 'true' ? true : false) : keyValue[1]) : Number(keyValue[1])
        })
        return JSON.stringify(nextTokenObject)
    }
    return nextToken
}

const getMany = async (resource, params) => {
    try {
        const promiseResults = await Promise.all(params.ids.map(async (id) => getOne(resource, { id })));
        const items = promiseResults.map(item => item.data).filter(item => item)
        return { data: items, total: items.length };
    } catch (error) {
        console.error(error)
        throw new Error('Sorry, could not read data.')
    }
}

const create = async (resource, params) => {
    try {
        const mutationData = getMutation(resource, MUTATIONS.CREATE);
        const variables = { input: params.data };
        const data = await fetchWithToken('POST', mutationData.mutation, variables);
        let response = data[mutationData.mutationName]
        if (resource === 'textMessages') {
            response = handleTextMessage(response)
        }
        return { data: response };
    }
    catch (error) {
        console.error(error)
        throw new Error('Sorry, could not save data.')
    }
}

const update = async (resource, params) => {
    try {
        const mutationData = getMutation(resource, MUTATIONS.UPDATE);
        const variables = { input: { ...params.data, id: params.id } };
        cleanVariablesInput(variables.input);
        const data = await fetchWithToken('POST', mutationData.mutation, variables);
        let response = data[mutationData.mutationName]
        if (resource === 'textMessages') {
            response = handleTextMessage(response)
        }
        return { data: response };
    }
    catch (error) {
        console.error(error)
        throw new Error('Sorry, could not save data.')
    }
}

const deleteOne = async (resource, params) => {
    try {
        const mutationData = getMutation(resource, MUTATIONS.DELETE);
        const variables = { input: { ...params.data, id: params.id } };
        cleanVariablesInput(variables.input);
        const data = await fetchWithToken('POST', mutationData.mutation, variables);
        return { data: data[mutationData.mutationName] };
    } catch (error) {
        console.error(error)
        throw new Error('Sorry, could not delete data.')
    }
}

const saveState = () => sessionStorage.setItem('paginationData', JSON.stringify(paginationData, null, 0))

const handleTextMessage = (response) => {
    const multipleItems = response?.items?.length > 1
    const successItem = response?.items?.find(item => item?.status)
    if (!multipleItems && !successItem) {
        throw new Error('Unfortunately, the message was not sent. Please try again.')
    }

    const hasSomeError = multipleItems && response?.items?.some(item => !item?.status)
    return multipleItems ? {
        ...(successItem || { id: 0, success: true }),
        hasSomeError
    } : successItem
}

const detectDuplicates = async (table, currentObjectId, items) => {
    try {
        const result = await fetchWithToken('POST', queries.listDuplicates, {
            filter: {
                table, currentObjectId, items
            }
        });
        const resultsWithoutCurrentItem = result?.listDuplicates?.items
        const isDuplicate = resultsWithoutCurrentItem?.length > 0
        return { isDuplicate, items: resultsWithoutCurrentItem }
    } catch (error) {
        console.error(error)
        throw new Error('Sorry, could not save data.')
    }
}

const customQuery = async (queryName, variables) => {
    try {
        const query = queries[queryName]
        const data = await fetchWithToken('POST', query, variables);
        return { data };
    } catch (error) {
        console.error(error)
        throw new Error('Sorry, could not read data.')
    }
}

const BQDataProvider = {
    getList,
    getOne,
    create,
    update,
    getMany,
    delete: deleteOne,
    deleteMany: () => { },
    saveState,
    detectDuplicates,
    customQuery
};

export default BQDataProvider;
