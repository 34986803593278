import React, { useState, useEffect } from 'react';
import { useInput } from 'react-admin';
import '../../GridSelector.css';

function GridSelector({
    source,
    rows,
    columns,
    topTitle = '',
    sideTitle = '',
    sideTitleRotation = 0,
    onSelectionChange,
    ...rest
}) {
    const {
        field,
        fieldState,
        formState,
        isRequired,
    } = useInput({ source, ...rest });

    const { value, onChange } = field;
    const { error, touched } = fieldState;

    const [selection, setSelection] = useState(() =>
        value != null && Array.isArray(value)
            ? value
            : Array.from({ length: rows }, () => Array(columns).fill(0))
    );
    const [animatingCells, setAnimatingCells] = useState({});

    useEffect(() => {
        setSelection(
            value != null && Array.isArray(value)
                ? value
                : Array.from({ length: rows }, () => Array(columns).fill(0))
        );
        setAnimatingCells({});
    }, [rows, columns, value]);

    const handleCellClick = (rowIndex, colIndex) => {
        setSelection((prevSelection) => {
            const newSelection = prevSelection.map((row, i) =>
                row.map((cell, j) => {
                    if (i === rowIndex && j === colIndex) {
                        return cell === 0 ? 1 : 0;
                    }
                    return cell;
                })
            );

            if (onChange) {
                // Serialize the new selection if necessary
                onChange(newSelection);
            }

            if (onSelectionChange) {
                onSelectionChange(newSelection);
            }

            return newSelection;
        });

        const cellKey = `${rowIndex}-${colIndex}`;
        setAnimatingCells((prev) => ({
            ...prev,
            [cellKey]: true,
        }));

        setTimeout(() => {
            setAnimatingCells((prev) => ({
                ...prev,
                [cellKey]: false,
            }));
        }, 200);
    };

    const rotationClass = `rotate-${sideTitleRotation}`;

    const gridCellsWidth = columns * 44;
    const gridCellsHeight = rows * 44;
    const labelWidth = 54;
    const labelHeight = 44;
    const totalGridWidth = gridCellsWidth + labelWidth;
    const totalGridHeight = gridCellsHeight + labelHeight;

    return (
        <div className="grid-wrapper" style={{ width: totalGridWidth }}>
            {topTitle && <div className="grid-top-title">{topTitle}</div>}

            <div className="grid-main">
                {sideTitle && (
                    <div
                        className={`grid-side-title ${rotationClass}`}
                        style={{ height: totalGridHeight }}
                    >
                        {sideTitle}
                    </div>
                )}

                <div className="grid-container">
                    {/* Column labels */}
                    <div className="grid-labels">
                        {/* Empty corner */}
                        <div className="grid-label-empty"></div>
                        {Array.from({ length: columns }, (_, colIndex) => (
                            <div key={colIndex} className="grid-label-column">
                                {colIndex + 1}
                            </div>
                        ))}
                    </div>

                    {/* Grid rows */}
                    <div className="grid-rows">
                        {selection.map((row, rowIndex) => (
                            <div key={rowIndex} className="grid-row">
                                {/* Row label */}
                                <div className="grid-label-row">
                                    {rowIndex + 1}
                                </div>
                                {/* Grid cells */}
                                <div className="grid-row-cells">
                                    {row.map((cell, colIndex) => {
                                        const cellKey = `${rowIndex}-${colIndex}`;
                                        const isSelected = cell === 1;
                                        const isAnimating =
                                            animatingCells[cellKey];

                                        return (
                                            <div
                                                key={colIndex}
                                                className={`grid-cell${isSelected
                                                    ? ' selected'
                                                    : ''
                                                    }${isAnimating
                                                        ? ' animating'
                                                        : ''
                                                    }`}
                                                onClick={() =>
                                                    handleCellClick(
                                                        rowIndex,
                                                        colIndex
                                                    )
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Display validation error if any */}
                {touched && error && <span>{error}</span>}
            </div>
        </div>
    );
}

export default GridSelector;
