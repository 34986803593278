import { Table, TableBody, TableHead } from '@material-ui/core';
import React, { useState } from 'react';
import { Button } from 'ra-ui-materialui';
import { SoftDeleteListButton } from './SoftDeleteListButton';
import { getLabel } from '../../utils/textUtils';
import { useFormContext, useFieldArray } from 'react-hook-form'
import { getFromCache } from '../../utils/globals';

function BQEditableDataGrid(props) {
    const {
        disableReorder,
        disableRemove,
        disableAdd,
        readOnly,
        allEditable,
        langCode
    } = props
    const [editedIndex, setEditedIndex] = useState();
    const classes = getFromCache('bqClasses');

    const { control } = useFormContext();
    const { fields, append, remove, swap } = useFieldArray({
        control,
        name: props.source
    })

    const handleAddItem = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex(fields.length);
        append({})
    }

    const handleRemoveItem = (index) => {
        remove(index)
    }

    const handleMoveUp = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        if (index > 0) {
            swap(index, index - 1);
            if (editedIndex === index) setEditedIndex(index - 1);
        }
    }

    const handleMoveDown = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        if (index < fields.length - 1) {
            swap(index, index + 1);
            if (editedIndex === index) setEditedIndex(index + 1);
        }
    }

    const handleEdit = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex(index);
    }

    const handleDoneEditing = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditedIndex();
    }

    return (
        <>
            {!disableAdd && !readOnly && (
                <div style={{ margin: '16px -62px' }}>
                    <div
                        className={classes.addButton}
                        style={{ transform: 'translateX(42px) translateY(10px)' }}
                    ></div>
                    <Button
                        id="add_button"
                        label="Add"
                        onClick={handleAddItem}
                        className="RaListToolbar-floating"
                    />
                </div>
            )}

            <Table style={{ width: '100%' }}>
                <TableHead className={classes.BQTableHeader}>
                    <th
                        style={{
                            maxWidth: '32px',
                            minWidth: '32px',
                            paddingLeft: '8px',
                            textAlign: 'left',
                            verticalAlign: 'top'
                        }}
                    >
                        #
                    </th>
                    {createHeader(props)}
                    <th style={{ width: '0px' }}></th>
                </TableHead>

                <TableBody>
                    {fields?.map((field, index) => (
                        <tr key={`${field.id}_${langCode}`} className={classes.bqTableRow}>
                            <td className={classes.editableListNumberingColumn}>
                                <div className={classes.editableListNumbering}>{index + 1}</div>
                            </td>
                            {createChildren({
                                ...props,
                                index,
                                field,
                                editedIndex,
                                classes,
                                allEditable: !readOnly && allEditable,
                                readOnly
                            })}
                            <td>
                                <table style={{ width: '88px', minWidth: '88px' }}>
                                    <tr>
                                        {!readOnly && !allEditable && (
                                            <td
                                                id={`${index !== editedIndex ? `edit_` : 'done_'
                                                    }${index}`}
                                                className={
                                                    index !== editedIndex
                                                        ? classes.editButton
                                                        : classes.saveButton
                                                }
                                                title={index !== editedIndex ? 'Edit' : 'Save'}
                                                onClick={(e) =>
                                                    index !== editedIndex
                                                        ? handleEdit(e, index)
                                                        : handleDoneEditing(e, index)
                                                }
                                            ></td>
                                        )}
                                        {!disableReorder && !readOnly && (
                                            <>
                                                <td
                                                    className={`${classes.upButton} ${classes.thinButton}`}
                                                    onClick={(e) => handleMoveUp(e, index)}
                                                    title="Move up"
                                                ></td>
                                                <td
                                                    className={`${classes.downButton} ${classes.thinButton}`}
                                                    onClick={(e) => handleMoveDown(e, index)}
                                                    title="Move down"
                                                ></td>
                                            </>
                                        )}
                                        {!disableRemove && !readOnly && (
                                            <td className={classes.editableListNumberingColumn}>
                                                <SoftDeleteListButton
                                                    id={`delete_button_${index}`}
                                                    title="Delete Item"
                                                    onConfirm={() => handleRemoveItem(index)}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

const createHeader = ({ children: baseChildren }) => {
    const children = React.Children.toArray(baseChildren).filter(Boolean);
    return children.map((child, index) => (
        <th
            key={index}
            style={{
                ...child.props.style,
                textAlign: 'left',
                lineHeight: '1.6em',
                width: child.props.width ? `${child.props.width}px` : 'auto',
                minWidth: child.props.width ? `${child.props.width}px` : undefined,
                maxWidth: child.props.width ? `${child.props.width}px` : undefined
            }}
        >
            {`${getLabel(child)}${child.props.validate ? ' *' : ''}`}
        </th>
    ));
};

const createChildren = ({
    index,
    source,
    field,
    children: baseChildren,
    editedIndex,
    classes,
    allEditable,
    readOnly
}) => {
    const children = React.Children.toArray(baseChildren).filter(Boolean);
    return children.map((child) => {
        const childSource = child.props.source;
        const sourceProp = `${source}[${index}].${childSource}`;
        const isReadOnly =
            child.props.readOnly ||
            readOnly ||
            (allEditable ? false : index !== editedIndex);

        const childComponent = React.cloneElement(child, {
            ...child.props,
            source: sourceProp,
            noLabel: true,
            readOnly: isReadOnly,
            key: `${field.id}-${childSource}` // Ensure unique key
        });

        return (
            <td
                key={`${field.id}-${childSource}-td`}
                className={classes.editableListDataColumn}
                style={child.props.style}
            >
                {childComponent}
            </td>
        );
    });
};

export default BQEditableDataGrid;
