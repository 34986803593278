/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    Children,
    cloneElement,
    isValidElement,
} from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getBasePath } from './BQUI';
import { getFromCache } from '../../utils/globals';
import { deepEqual } from '../../utils/bqTools';

const useStyles = makeStyles(
    theme => ({
        root: {
            padding: 0,
            marginBottom: 0,
            '& > li:last-child': {
                borderBottom: 'none',
            },
        },
        line: {
            display: 'flex',
            listStyleType: 'none',
            borderBottom: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down('xs')]: { display: 'block' },
            '&.fade-enter': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
            },
            '&.fade-enter-active': {
                opacity: 1,
                transform: 'translateX(0)',
                transition: 'all 500ms ease-in',
            },
            '&.fade-exit': {
                opacity: 1,
                transform: 'translateX(0)',
            },
            '&.fade-exit-active': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
                transition: 'all 500ms ease-in',
            },
        },
        index: {
            width: '3em',
            paddingTop: '1em',
            [theme.breakpoints.down('sm')]: { display: 'none' },
        },
        form: { flex: 2 },
        action: {
            paddingTop: '0.5em',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'RaSimpleFormIterator' }
);

const BQAddButton = props => {
    const bqClasses = getFromCache('bqClasses');
    return <Button {...props} className={bqClasses.addButton} title={'Add'}></Button>
}

const BQRemoveButton = props => {
    const bqClasses = getFromCache('bqClasses');
    return <Button {...props} className={bqClasses.removeButton} title={'Remove'}></Button>
}

const compareObjects = (obj1, obj2) => {
    const filteredObj1 = { ...obj1 }
    const filteredObj2 = { ...obj2 }
    delete filteredObj1.id
    delete filteredObj2.id
    return deepEqual(filteredObj1, filteredObj2)
}

const BQIterator = props => {
    const {
        addButton = <BQAddButton />,
        removeButton = <BQRemoveButton />,
        children,
        className,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        variant,
        margin,
        noNumbering,
        bottomLine,
        keyField
    } = props;

    const { control, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: source
    });

    const fieldValues = watch(source) || [];

    const classes = useStyles(props);

    const removeField = index => remove(index)

    const addField = () => {
        append()
    }

    const basePath = getBasePath()
    return (
        <ul className={classNames(classes.root, className)}>
            {fields?.map((field, i) => {
                const fieldValue = fieldValues[i] || {};
                const isFieldDuplicated = fieldValues.filter(item => compareObjects(item, fieldValue)).length > 1;
                const key = `${field.id}_${keyField ? fieldValue[keyField] : ''}`;
                return (
                    <li key={key} className={classes.line} style={{ borderBottom: 'none' }}>
                        {!noNumbering && <Typography
                            variant="body1"
                            className={classes.index}
                        >
                            {i + 1}
                        </Typography>}
                        <section className={classes.form}>
                            {Children.map(
                                children,
                                (child, childIndex) => {
                                    if (!isValidElement(child)) {
                                        return null;
                                    }
                                    const {
                                        source: childSource,
                                    } = child.props;
                                    return (
                                        <div
                                            key={`${field.id}-child-${childIndex}`}
                                            variant={variant}
                                            margin={margin}
                                        >{
                                                setChild(child, i, {
                                                    parentSource: source,
                                                    isFieldDuplicated,
                                                    basePath,
                                                    resource: resource,
                                                    disabled,
                                                })
                                            }
                                        </div>
                                    );
                                }
                            )}
                            {bottomLine && <hr style={{ width: '100%' }} />}
                        </section>
                        {!disabled && !disableRemove &&
                            (
                                <span className={classes.action}>
                                    {cloneElement(removeButton, {
                                        onClick: () => removeField(i),
                                        className: classNames(
                                            'button-remove',
                                            `button-remove-${source}-${i}`
                                        ),
                                    })}
                                </span>
                            )}
                    </li>
                )
            })}

            {!disabled && !disableAdd && <li className={classes.line}>
                <span className={classes.action}>
                    {cloneElement(addButton, {
                        onClick: () => addField(),
                        className: classNames(
                            'button-add',
                            `button-add-${source}`
                        ),
                    })}
                </span>
            </li>}
        </ul>
    );
};

const setChild = (child, i, customProps) => {
    const { source, originalSource, children } = child.props
    const { parentSource } = customProps
    const sourceToUse = originalSource || source
    const mergedSource = parentSource ? `${parentSource}[${i}]${sourceToUse ? `.${sourceToUse}` : ''}` : sourceToUse
    return cloneElement(child, {
        ...child.props,
        ...customProps,
        originalSource: source,
        source: mergedSource,
        children: children && Children.map(children, innerChild => {
            if (!isValidElement(innerChild)) {
                return null;
            }
            return setChild(innerChild, i, { ...customProps, parentSource: '' })
        })
    })
}

BQIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

BQIterator.propTypes = {
    defaultValue: PropTypes.any,
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    // @ts-ignore
    fields: PropTypes.object,
    // @ts-ignore
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({}),
};

export default BQIterator;
