/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Datagrid,
    DataProviderContext,
    List,
    ListContextProvider,
    useList,
    useListContext
} from 'react-admin';
import { Button } from 'ra-ui-materialui';

import { BQSimpleForm, FunctionField } from './Generic/bq-form-components';
import { BQFilter, BQPagination, BQProgress, BQSection, BQSideBySide, BQToolbar } from './Generic/BQUI';
import { decapitalize, fixValue, getDateTime, parseTime, lineBreaks, getEMFTimeString, getValueFromEnum } from '../utils/textUtils';

import { connect } from 'react-redux';
import { useEffect } from 'react';
import { bqAuthData } from '../utils/bq-auth';
import { BQExporter } from '../utils/bq_export';
import { bqBlue, useBQStyles } from '../themes';
import { charCheck } from '../utils/constants';
import { useLocation } from 'react-router-dom';
import { getFromCache, useGetAppSettings } from '../utils/globals';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { getInitialPhase } from './Generic/UITools';

const exerciseRatings = {
    0: 'Not rated',
    1: 'Yes',
    2: 'Yes',
    3: 'No',
    4: 'Partially - less sets',
    5: 'Partially - less repetitions',
    6: 'Partially - less weight / resistance',
}

let exerciseListData = {}
let bqExporter = new BQExporter()

const boxStyle = {
    padding: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    borderRadius: 3,
    backgroundClip: 'border-box',
    marginTop: '16px',
    marginBottom: '16px',
}

const wordBreakStyle = { wordBreak: 'break-all' }

const displayOU = (val) => val?.endsWith('.') ? val.slice(0, val.length - 1) : val

const defaultValue = 'N/A'
const DefaultValueField = (props) => <FunctionField {...props} defaultValue={defaultValue} />

const AnalyticsView = (props) => {
    const location = useLocation()
    const pathname = location.pathname
    const patientId = pathname.match(/\/(.*)?\/(.*)/)?.[2]

    const dataProvider = React.useContext(DataProviderContext);

    const [listsState, setListsState] = React.useState({
        isLoading: true
    })

    useEffect(() => {
        Promise.all([
            dataProvider.getList('exercises', { pagination: { page: 1, perPage: 10000 } }),
            dataProvider.getList('exercisesCategorys', { pagination: { page: 1, perPage: 10000 } })
        ]).then(data => {
            exerciseListData = {
                exercises: data[0].data,
                categories: data[1].data,
            }
            setListsState({})
        })
    }, [])

    const ComponentView = patientId ? TreatmentAnalytics : PatientAnalyticsList
    return listsState.isLoading ? <BQProgress /> : <ComponentView {...props} patientId={patientId} />
}

const PatientAnalyticsList = (props) => {
    const { isSuperAdmin, isViewer, subjectOrPatient } = bqAuthData
    const showMaxData = isSuperAdmin || isViewer

    const { appSettings } = props
    const { Phases } = appSettings || {}
    const initialPhase = getInitialPhase(Phases)

    const exporterRef = bqExporter?.createRefForExport(`${subjectOrPatient}s`)

    return <List
        id={`patientsAnalytics`}
        perPage="50"
        pagination={<BQPagination />}
        filter={props.patientId ? { patientId: props.patientId } : undefined}
        actions={<Button
            style={{ minWidth: '160px' }}
            label="Export List"
            onClick={() => bqExporter.exportData(`Patients Analytics`)}
            className='RaListToolbar-floating'
        />}
        filters={<BQFilter />}
        sort={{ field: 'idNumber', order: 'ASC' }}
    >
        <BQSection title={`${subjectOrPatient}s`} style={{ maxWidth: '100%' }}>
            <br />
            <Datagrid
                bulkActionButtons={false}
                ref={exporterRef}
                rowClick={(id, basePath, record) => `./${id}?idNumber=${record.idNumber}`}
            >
                <TextField label={`${subjectOrPatient} ${subjectOrPatient === 'Subject' ? 'ID' : 'name'}`} source="idNumber" />
                {showMaxData && <DefaultValueField label="Clinic" source="organizationalUnit" value={val => displayOU(val)} />}
                {Phases && <FunctionField source="phase" label="Clinical study stage" value={v => (Phases?.find(item => item.id === v) || initialPhase)?.name} />}
                <DefaultValueField label="Counted sessions" source="number_fully_completed_treatments" />
                <DefaultValueField label="Initiated sessions" source="number_of_initiated_treatments" />
                <DefaultValueField label="Last initiated session" source="last_treatment_date" value={val => getDateTime(new Date(val))} />
                <DefaultValueField label="Counted sessions current week" source="treatments_current_week" />
                <DefaultValueField label="Not counted sessions current week" source="incomplete_treatments_current_week" />
                <DefaultValueField label="Counted sessions previous week" source="treatments_previous_week" />
                <FunctionField label="Completed / Discontinued" source="participationConcluded" value={(v) => !!v ? charCheck : ''} />
            </Datagrid>
        </BQSection >
    </List >
}

const TreatmentAnalytics = (props) => {
    const { subjectOrPatient } = bqAuthData
    const dataProvider = React.useContext(DataProviderContext);
    const [treatmentsForPatient, setTreatmentsForPatient] = React.useState({
        isLoading: true
    })

    const [patientData, setPatientData] = React.useState({
        isLoading: true
    })

    const { patientId } = props
    const location = useLocation()
    const idNumber = location.search?.match(/(.*?)(idNumber=(.*?)(&|$)|$)/)[3]

    useEffect(() => {
        Promise.all([
            dataProvider.getList('treatmentAnalytics', {
                pagination: { page: 1, perPage: 10000 },
                filter: { patientId: patientId },
                filterAsJson: true
            }),
            dataProvider.getOne('patients', { id: patientId }),
            dataProvider.getOne('patientAnalyticss', { id: patientId })
        ]).then(patientAnalyticsData => {
            let countedTreatmentsCounter = 0
            setTreatmentsForPatient({
                treatments: patientAnalyticsData[0].data.map((treatment) => {
                    return {
                        ...treatment,
                        id: treatment.treatmentId,
                        countedIndex: treatment.isCounted ? ++countedTreatmentsCounter : '-',
                    }
                })
            })

            setPatientData({
                ...patientAnalyticsData[1].data,
                ...patientAnalyticsData[2].data,
            })
        })
    }, [])

    return (patientData.isLoading ? <BQProgress /> :
        <div style={{ marginTop: 64 }}>
            <BQSimpleForm
                toolbar={
                    <BQToolbar
                        label={`Sessions for ${idNumber}`}
                        hideButtons
                        exporter={() => bqExporter.exportData(`${idNumber} Analytics`)}
                    />
                }
            >
                <BQSection title={`${subjectOrPatient} info`} headerPadding={0} style={{
                    maxWidth: '100%',
                    marginBottom: 32
                }}>
                    <PatientData {...props} patientData={[patientData]} />
                </BQSection>
                {
                    treatmentsForPatient.isLoading ? <BQProgress /> : (
                        <BQSection title={'List of sessions'} headerPadding={0} style={{
                            maxWidth: '100%',
                        }}>
                            <TreatmentsList {...props} treatments={treatmentsForPatient.treatments} idNumber={idNumber} patientId={patientId} patientData={[patientData]} />
                        </BQSection>
                    )
                }
            </BQSimpleForm>
        </div >
    )
}

const PatientData = (props) => {
    const { isAlzheimers, subjectOrPatient } = bqAuthData
    const { appSettings, patientData } = props
    const { Phases } = appSettings || {}
    const initialPhase = getInitialPhase(Phases)
    if (!patientData) {
        return <></>
    }

    const exporterRef = bqExporter?.createRefForExport(`${subjectOrPatient} Info`)

    const listContext = useList({
        data: patientData,
        ids: patientData.map((patient) => patient.id)
    })

    const { treatmentTypes } = appSettings || {}
    return (
        <ListContextProvider value={listContext} >
            <Datagrid
                ref={exporterRef}
                bulkActionButtons={false}
            >
                <DefaultValueField label="Clinic" source="organizationalUnit" value={val => displayOU(val)} />
                {Phases && <FunctionField source="phase" label="Clinical study stage" value={v => (Phases?.find(item => item.id === v) || initialPhase)?.name} />}
                <DefaultValueField label="Counted sessions" source="number_fully_completed_treatments" />
                <DefaultValueField label="Initiated sessions" source="number_of_initiated_treatments" />
                <DefaultValueField label="Counted sessions current week" source="treatments_current_week" />
                <DefaultValueField label="Not counted sessions current week" source="incomplete_treatments_current_week" />
                <DefaultValueField source="height" />
                <DefaultValueField source="gender" label="Sex" fromAppSettings />
                {!isAlzheimers && treatmentTypes && <DefaultValueField
                    source="treatmentType"
                    label={treatmentTypes?.label}
                    value={val => treatmentTypes?.choices?.find(choice => choice.id === val)?.name}
                />}
                <DefaultValueField source="deviceSize" label="Device type" fromAppSettings="deviceType" />
                <DefaultValueField source="caregiverRelation" fromAppSettings="relation" />
            </Datagrid >
        </ListContextProvider >
    )
}

const TreatmentsList = (props) => {
    const { treatments, idNumber, ...rest } = props
    if (!treatments) {
        return <></>
    }

    const data = treatments.map((treatment, index) => {
        return { ...treatment, id: treatment.treatmentId, index: index + 1 }
    })

    const listContext = useList({
        data,
        ids: data.map((treatment) => treatment.id),
        sort: { field: 'index', order: 'ASC' }
    })

    return <ListContextProvider value={listContext}>
        <SortableTreatmentDataGrid {...rest} idNumber={idNumber} />
    </ListContextProvider >

}

const SortableTreatmentDataGrid = (props) => {
    const { isSuperAdmin, isViewer } = bqAuthData
    const { appSettings } = props
    const { Phases } = appSettings || {}
    const initialPhase = getInitialPhase(Phases)

    const showMaxData = isSuperAdmin || isViewer
    const classes = getFromCache('bqClasses')

    const { setSort } = useListContext();

    useEffect(() => {
        setSort({ field: 'treatment_start', order: 'ASC' })
    }, []);

    const exporterRef = bqExporter?.createRefForExport('List of sessions')

    return <Datagrid

        bulkActionButtons={false}
        ref={exporterRef}
        expand={<TreatmentData idNumber={props.idNumber} patientData={props.patientData} />}
        expandSingle={true}
        setSort={setSort}
        rowSx={(r) => !r.phase || r.phase === initialPhase?.id ? { backgroundColor: 'rgba(148, 156, 226, .3)' } : {}}
    >
        <FunctionField
            label={<span style={{ opacity: 0 }} id="lbl_counter_header">{String.fromCharCode(0)}</span>}
            value={(val) =>
                <td id="lbl_counter" className={classes.editableListNumberingColumn}><div className={classes.editableListNumbering}>{val.index}</div></td>
            } />
        <TextField label="#" source='countedIndex' sortable={false} />
        <DefaultValueField source="treatment_start"
            label="Started at"
            value={val => lineBreaks(getDateTime(new Date(parseInt(val))) || '')}
        />
        <DefaultValueField label="Week number" source='week_number' value={val => val !== -1 ? val : ''} />
        {Phases && <FunctionField source="phase" label="Clinical study stage" value={v => (Phases?.find(item => item.id === v) || initialPhase)?.name} />}
        <DefaultValueField source='session_user_email' label="User email" value={val =>
            <div style={wordBreakStyle} title={val}>{val || defaultValue}</div>} />
        <DefaultValueField label="EMF duration" source="app_emf_time" value={(app_emf_time, record) => getEMFTimeString(app_emf_time, record.device_emf_time)} />
        <DefaultValueField source='number_of_pauses' />
        {showMaxData && <DefaultValueField source='first_emf_time_before_pause' label="Time before first pause" value={val => parseTime(val)} />}
        {showMaxData && <DefaultValueField source='time_to_first_resume' label="Pause duration" value={val => parseTime(val)} />}
        <DefaultValueField label="Session status" source='isCounted' value={val => !val ? 'Not counted' : 'Counted'} />
        <DefaultValueField source='numOfQompasses' label="# of Qompasses" />
        {showMaxData && <DefaultValueField source='qompassDetails' value={val => lineBreaks(val, defaultValue)} />}
        <DefaultValueField source='avg_category_rating' label="Avg. exercise category rating" value={val => val && fixValue(val, 2) || 'N/A'} />
        {showMaxData && <DefaultValueField source='device_mac' label="Device serial number" />}
        {showMaxData && <DefaultValueField source='tablet_user_email' label="Tablet user" value={val =>
            <div style={wordBreakStyle} title={val}>{val || defaultValue}</div>} />}
    </Datagrid >
}

const TreatmentData = (props) => {
    const { idNumber } = props
    const dataProvider = React.useContext(DataProviderContext);
    const [sessionData, setSessionData] = React.useState({
        isLoading: true
    })

    useEffect(() => {
        const parsedTreatmentId = props.id
        dataProvider.getOne('SessionAnalyticss', {
            id: parsedTreatmentId,
        }).then(response => {
            const { sessionEvents, sessionCategories, sessionExercises } = response.data
            setSessionData({
                sessionEvents,
                sessionCategories,
                sessionExercises
            })
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    return sessionData.isLoading ? <BQProgress /> : <>
        <div style={{
            backgroundColor: '#FAFAFA',
            ...boxStyle,
            boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
        }} ref={props.ref}>
            <BQSideBySide style={{ marginTop: 0 }} columnGap={128}>
                <SessionCategories id={props.id} sessionCategories={sessionData.sessionCategories} idNumber={idNumber} />
                <SessionEvents id={props.id} sessionEvents={sessionData.sessionEvents} idNumber={idNumber} />
            </BQSideBySide>
            <SessionExercises id={props.id} sessionExercises={sessionData.sessionExercises} idNumber={idNumber} />
        </div>
    </>
}

const SessionEvents = (props) => {
    const { sessionEvents } = props
    if (!sessionEvents) {
        return <></>
    }

    const exporterRef = bqExporter?.createRefForExport(`Sessions Events`)

    const listContext = useList({
        data: sessionEvents.map((item, index) => { return { ...item, index: index + 1 } }),
        ids: sessionEvents.map((item) => item.id)
    })
    return (
        <BQSection title={`Sessions Events`} style={{ maxWidth: '100%' }} headerPadding={0}>
            <ListContextProvider value={listContext}>
                <Datagrid ref={exporterRef} bulkActionButtons={false}>
                    <TextField label="#" source='index' />
                    <DefaultValueField source='status' />
                    <DefaultValueField label="Time" source='timestamp_string' value={val => getDateTime(val)} />
                </Datagrid >
            </ListContextProvider >
        </BQSection>
    )
}

const SessionCategories = (props) => {
    const { sessionCategories } = props
    if (!sessionCategories) {
        return <></>
    }

    const exporterRef = bqExporter?.createRefForExport(`Sessions Categories`)

    const listContext = useList({
        data: sessionCategories.map((item, index) => { return { ...item, index: index + 1 } }),
        ids: sessionCategories.map((item) => item.id)
    })
    return (
        <BQSection title={`Sessions Categories`} style={{ maxWidth: '100%' }} headerPadding={0}>
            <ListContextProvider value={listContext}>
                <Datagrid ref={exporterRef} bulkActionButtons={false}>
                    <TextField label="#" source='index' />
                    <DefaultValueField label="Category name" source="exerciseCategoryId" value={val => exerciseListData.categories.find(item => item.id === val)?.name} />
                    <DefaultValueField source="rating" />
                </Datagrid >
            </ListContextProvider >
        </BQSection>
    )
}

const SessionExercises = (props) => {
    const { sessionExercises } = props
    if (!sessionExercises) {
        return <></>
    }

    const exporterRef = bqExporter?.createRefForExport(`Sessions Exercises`)

    const listContext = useList({
        data: sessionExercises.map((item, index) => { return { ...item, index: index + 1 } }),
        ids: sessionExercises.map((item) => item.id)
    })
    return (
        <BQSection title={`Sessions Exercises`} style={{ maxWidth: '100%' }} headerPadding={0}>
            <ListContextProvider value={listContext}>
                <Datagrid ref={exporterRef} rowStyle={exericseRowStyle} bulkActionButtons={false}>
                    <TextField label="#" source='index' />
                    <DefaultValueField label="Time" source="timestamp" value={val => getDateTime(parseInt(val))} />
                    <DefaultValueField label="Exercise name" source="exerciseId" value={val => exerciseListData.exercises.find(item => item.id === val)?.name} />
                    <DefaultValueField source='numOfSets' label="Sets" />
                    <DefaultValueField label="Repetitions/Seconds" value={item => `${item.numOfRepetitions}${item.secsInsteadOfReps ? ' sec' : ''}`} />
                    <DefaultValueField source='progressionsList' />
                    <DefaultValueField source='elapsed' label="Length" value={val => parseTime(parseInt(val / 1000))} />
                    <DefaultValueField source='rating' label="Completed" value={val => exerciseRatings[val]} />
                </Datagrid >
            </ListContextProvider >
        </BQSection>
    )
}

const exericseRowStyle = props => {
    const { rating } = props
    switch (rating) {
        case 3:
            return { backgroundColor: 'rgba(64, 64, 64, 0.2)' }
        case 4:
        case 5:
        case 6:
            return;
        default:
            return
    }
}

export default BQCRUDBuilder({
    Create: null,
    Edit: AnalyticsView,
    List: AnalyticsView,
    resetOnList: true
})