import { useEffect, useRef } from "react"
import { downloadCSV } from "react-admin"

export function BQExporter() {
    const exportStructure = {}
    let fileNameOverride = null
    let useFileNameOverride = false

    const getCSVItemText = (str) => {
        let text = str?.trim()
        text = text === null || text === undefined ? '' : text
        text = text.indexOf(',') !== -1 ? `"${text}"` : text
        return text.replace(/\n/g, ' ')
    }

    this.addDataGrid = (structureItem, ref, systemId) => {
        if (ref) {
            exportStructure[structureItem] = {
                ref
            }
            if (systemId) {
                exportStructure[structureItem].systemId = systemId
            }
        }
    }

    const createExportData = (multipleExpand) => {
        useFileNameOverride = false
        console.log(exportStructure)
        Object.keys(exportStructure)?.sort().forEach(structureItem => {
            delete exportStructure[structureItem].exportData
            const { ref } = exportStructure[structureItem]
            if (ref) {
                const header = []
                let data = []
                for (let row of ref?.current?.rows || []) {
                    const isHeader = row.className.indexOf('MuiTableRow-head') !== -1
                    const isExpanded = row.id.indexOf('-expand') !== -1
                    if (isExpanded && !multipleExpand) {
                        data = [data[data.length - 1]]
                        useFileNameOverride = true
                        break;
                    }

                    const rowData = []
                    for (let column of row.children) {
                        rowData.push(getCSVItemText(column.innerText))
                    }
                    if (isHeader) {
                        rowData.forEach(item => header.push(item))
                    } else {
                        data.push(rowData)
                    }
                }
                const nullHeaderIndexes = []
                if (header.length) {
                    header.forEach((item, index) => { !item && nullHeaderIndexes.unshift(index) })
                    nullHeaderIndexes.forEach(nullIndex => {
                        header.splice(nullIndex, 1)
                        data.forEach(row => row.splice(nullIndex, 1))
                    })
                    const exportData = header.map(item => putInQuotes(item)).join(',') + '\n' + data.map(row => row.map(item => putInQuotes(item)).join(',')).join('\n')
                    exportStructure[structureItem].exportData = exportData
                }
            }
        })
    }

    this.exportData = (fileName, multipleExpand) => {
        createExportData(multipleExpand)
        const exportString = Object.keys(exportStructure)
            .map(key => exportStructure[key].exportData && (putInQuotes(getCSVItemText(key)) + '\n' + exportStructure[key].exportData))
            .join('\n\n\n')
        downloadCSV(exportString, useFileNameOverride && fileNameOverride || fileName)
    }

    this.removeById = (id) => {
        Object.keys(exportStructure).forEach(key => {
            if (exportStructure[key].systemId === id) {
                delete exportStructure[key]
            }
        })
    }
    this.removeAllNested = () => {
        Object.keys(exportStructure).forEach(key => {
            if (exportStructure[key]?.systemId?.match(/\(.*?\)/)) {
                delete exportStructure[key]
            }
        })
    }

    this.createRefForExport = (title) => {
        const ref = useRef()
        useEffect(() => {
            this.addDataGrid(title, ref)
        }, [ref])
        return ref
    }

    this.setFileName = (fileName) => {
        fileNameOverride = fileName
    }
}

const putInQuotes = (str) => `"${str?.replaceAll('"', '""')}"`

export const exportObjectToCSV = (data, fileName) => {
    const headers = Object.keys(data[0])
    const csvContent = headers.map(item => putInQuotes(item)).join(',') + '\n' + data.map(item => headers.map(header => putInQuotes(item[header])).join(',')).join('\n')
    downloadCSV(csvContent, fileName)
}