/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {
    Edit,
    TextField,
    Create
} from 'react-admin';
import { getDuplicates, validate, validateDuplicates, validateString } from '../utils/validations';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQCheckbox, BQSimpleForm, TranslationInput } from './Generic/bq-form-components';
import { BQModelList, BQSection, BQToolbar, PhaseSelector } from './Generic/BQUI';
import { BQDropDown, BQInput } from './Generic/bq-input';

import BQEditableDataGrid from './Generic/BQEditableDataGrid';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';

const QuestionnaireList = () => (
    <BQModelList>
        <TextField source="name" />
        <TextField source="description" />
    </BQModelList>
)

const QuestionnaireEditor = props => {
    const { appSettings } = props
    const { questionnaireItemTypes, autofillFields, roles } = appSettings || {}

    const { language: { code: langCode, name: langName } } = props

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Questionnaire',
        duplicates: props.duplicates
    }

    return (
        <BQSimpleForm
            toolbar={<BQToolbar type="Questionnaire" />}
        >
            <BQSection title="Questionnaire Info" language={props.language} style={{ maxWidth: '100%' }}>
                <BQInput source="name" validate={validateString} duplicates={duplicatesCheckData} />
                <BQDropDown
                    label="For Role"
                    source="role"
                    choices={roles?.filter(item => item.id === 'Caregiver')}
                    validate={validate}
                    unsorted
                />
                <PhaseSelector appSettings={appSettings} />
                <BQInput source="description" />
            </BQSection>
            <BQSection title="Questions" language={props.language} style={{ maxWidth: '100%' }}>
                <BQEditableDataGrid source="items" langCode={langCode}>
                    <BQInput source="title" validate={validate} />
                    <TranslationInput translateHeader source="question" multiline width={480} validate={validate} />
                    <BQDropDown source="type" label="Type of question" choices={questionnaireItemTypes} unsorted validate={validate} width={160} />

                    <BQDropDown source="autofillField" label="AutoFill" choices={autofillFields} unsorted validate={validate} width={192} />

                    <BQCheckbox label={`Read only - AutoFill`} source="readOnlyAutofill" width={92} />

                    <TranslationInput translateHeader source="options" placeholder="Opt1|Opt2|Op3" />
                    {/* <TranslationInput translateHeader source="comment" multiline width={256} />
                    <BQInput source="commentIndex" width={64} type="number" />
                    <BQCheckbox label={`Enable "Other"`} source="enableOther" width={64} /> */}
                    <BQCheckbox label="Mandatory" source="required" width={64} />
                </BQEditableDataGrid>
            </BQSection>
        </BQSimpleForm>
    )
}

const mutationTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Questionnaire', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length) {
        return false
    }
    data.items?.forEach((item, index) => item.index = index + 1)
    return prepareFormData(data)
};

const QuestionnaireMutate = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => mutationTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...editorProps} record={{ items: [] }}>
                    <QuestionnaireEditor {...props} redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>) :
                (<Edit {...editorProps} >
                    <QuestionnaireEditor {...props} redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const QuestionnaireCreate = props => QuestionnaireMutate(true, props);

const QuestionnaireEdit = props => QuestionnaireMutate(false, props);

export default BQCRUDBuilder({
    Create: QuestionnaireCreate,
    Edit: QuestionnaireEdit,
    List: QuestionnaireList,
})
