export const deepEqual = (obj1, obj2) => {
    // Check if both values are identical (strict equality)
    if (obj1 === obj2) return true;

    // Check if both values are objects and not null
    if (
        typeof obj1 === 'object' && obj1 !== null &&
        typeof obj2 === 'object' && obj2 !== null
    ) {
        // Get the keys of both objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Check if both objects have the same number of keys
        if (keys1.length !== keys2.length) return false;

        // Loop through keys in obj1
        for (let key of keys1) {
            // Check if key exists in obj2
            if (!keys2.includes(key)) return false;

            // Recursively check for nested objects
            if (!deepEqual(obj1[key], obj2[key])) return false;
        }

        return true;
    } else {
        // If not objects or null, use strict equality
        return obj1 === obj2;
    }
}