/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser($input: CognitoUserModelInput!) {
    createCognitoUser(input: $input) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
      tempPassword
      __typename
    }
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser($input: CognitoUserModelInput!) {
    updateCognitoUser(input: $input) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
      tempPassword
      __typename
    }
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($input: IdInput!) {
    deleteCognitoUser(input: $input) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
      tempPassword
      __typename
    }
  }
`;
export const setFirebaseRegistrationToken = /* GraphQL */ `
  mutation SetFirebaseRegistrationToken(
    $input: CognitoFirebaseRegistrationTokenInput!
  ) {
    setFirebaseRegistrationToken(input: $input) {
      result
      __typename
    }
  }
`;
export const createTreatmentOnServer = /* GraphQL */ `
  mutation CreateTreatmentOnServer($input: CreateTreatmentOnServerInput) {
    createTreatmentOnServer(input: $input) {
      id
      objectData
      patient {
        id
        idNumber
        idNumber_scan
        gender
        height
        yearOfBirth
        dateOfBirth
        idOrPassport
        phoneNumber
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        deviceSize
        deviceInfo
        changesInDevice
        caregiverName
        caregiverRelation
        participationConcluded
        assessmentScores {
          timestamp
          shoulderAbduction
          fingerExtension
          WTSeconds
          WTAssistantLevel
          __typename
        }
        numberOfTreatments
        recentTreatmentDate
        controlPatient
        treatmentType
        treatmentProtocolId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        phase
        stats
        deletedAt
        updatedAt
        createdAt
        __typename
      }
      treatmentsForPatient {
        id
        patientId
        sessionId
        phase
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        status
        eventLog {
          timestamp
          status
          emfState
          waveformIndex
          stageId
          categoryId
          exerciseId
          __typename
        }
        notes
        treatmentId
        waveformIndex
        durations
        createdAt
        startedAt
        completedAt
        deletedAt
        zoomUserId
        zoomMeetingId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
              __typename
            }
            __typename
          }
          __typename
        }
        categories {
          treatmentStageId
          exerciseCategoryId
          rating
          __typename
        }
        exercises {
          treatmentStageId
          exerciseCategoryId
          exerciseId
          rating
          status
          notes
          startedAt
          elapsed
          __typename
        }
        updateId
        updatedAt
        version
        worksheets {
          timestamp
          role
          userId
          treatmentEMFTime
          deviceEMFTime
          isTreatmentCounted
          operatorPresent
          answers {
            index
            title
            question
            meta
            answer
            __typename
          }
          __typename
        }
        __typename
      }
      extra
      __typename
    }
  }
`;
export const createOrganizationalUnit = /* GraphQL */ `
  mutation CreateOrganizationalUnit($input: OrganizationalUnitInput) {
    createOrganizationalUnit(input: $input) {
      id
      facilityName
      organizationalUnit
      organizationalUnit_scan
      updatedAt
      deletedAt
      createdAt
      __typename
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient($input: PatientInput) {
    createPatient(input: $input) {
      id
      idNumber
      idNumber_scan
      gender
      height
      yearOfBirth
      dateOfBirth
      idOrPassport
      phoneNumber
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      deviceSize
      deviceInfo
      changesInDevice
      caregiverName
      caregiverRelation
      participationConcluded
      assessmentScores {
        timestamp
        shoulderAbduction
        fingerExtension
        WTSeconds
        WTAssistantLevel
        __typename
      }
      numberOfTreatments
      recentTreatmentDate
      controlPatient
      treatmentType
      treatmentProtocolId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      phase
      stats
      deletedAt
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createTreatmentStage = /* GraphQL */ `
  mutation CreateTreatmentStage($input: TreatmentStageInput) {
    createTreatmentStage(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      exerciseCategories
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExercisesCategory = /* GraphQL */ `
  mutation CreateExercisesCategory($input: ExercisesCategoryInput) {
    createExercisesCategory(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      exercises
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExercise = /* GraphQL */ `
  mutation CreateExercise($input: ExerciseInput) {
    createExercise(input: $input) {
      id
      name
      name_scan
      name_filter
      idInApp
      tip
      description
      nameTranslations {
        code
        text
        __typename
      }
      tipTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      numOfRepetitions
      numOfSets
      secsInsteadOfReps
      illustrationFile
      progressionsList
      equipmentList
      useAssessmentScores
      shoulderAbduction
      fingerExtension
      assessmentScoresMatrix
      walkTest
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment($input: EquipmentInput) {
    createEquipment(input: $input) {
      id
      imageUrl
      name
      name_scan
      idInApp
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProgression = /* GraphQL */ `
  mutation CreateProgression($input: ProgressionInput) {
    createProgression(input: $input) {
      id
      name
      name_scan
      nameTranslations {
        code
        text
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTreatmentProtocol = /* GraphQL */ `
  mutation CreateTreatmentProtocol($input: TreatmentProtocolInput) {
    createTreatmentProtocol(input: $input) {
      id
      name
      name_scan
      phase
      nextPhaseMinSessions
      nextPhaseMinDays
      numberOfTreatments
      totalDaysLimit
      weeklySessionsLimit
      partialSessionsAllowed
      sessionsCounterType
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTreatment = /* GraphQL */ `
  mutation CreateTreatment($input: CreateTreatmentInput) {
    createTreatment(input: $input) {
      id
      patientId
      sessionId
      phase
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      status
      eventLog {
        timestamp
        status
        emfState
        waveformIndex
        stageId
        categoryId
        exerciseId
        __typename
      }
      notes
      treatmentId
      waveformIndex
      durations
      createdAt
      startedAt
      completedAt
      deletedAt
      zoomUserId
      zoomMeetingId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      categories {
        treatmentStageId
        exerciseCategoryId
        rating
        __typename
      }
      exercises {
        treatmentStageId
        exerciseCategoryId
        exerciseId
        rating
        status
        notes
        startedAt
        elapsed
        __typename
      }
      updateId
      updatedAt
      version
      worksheets {
        timestamp
        role
        userId
        treatmentEMFTime
        deviceEMFTime
        isTreatmentCounted
        operatorPresent
        answers {
          index
          title
          question
          meta
          answer
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: DeviceInput) {
    createDevice(input: $input) {
      id
      serialNumber
      quickConnectNumber
      macAddress
      resistance
      deviceSize
      deletedAt
      updatedAt
      usage {
        timestamp
        tabletUserEmail
        userEmail
        patientIdNumber
        organizationalUnit
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage($input: LanguageInput) {
    createLanguage(input: $input) {
      id
      code
      name
      name_scan
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConsent = /* GraphQL */ `
  mutation CreateConsent($input: ConsentInput) {
    createConsent(input: $input) {
      id
      type
      userId
      patientId
      timestamp
      dateTime
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestionnaire = /* GraphQL */ `
  mutation CreateQuestionnaire($input: QuestionnaireInput) {
    createQuestionnaire(input: $input) {
      id
      role
      phase
      name
      name_scan
      description
      isActive
      items {
        index
        required
        type
        title
        question
        questionTranslations {
          code
          text
          __typename
        }
        comment
        commentTranslations {
          code
          text
          __typename
        }
        commentIndex
        options
        optionsTranslations {
          code
          text
          __typename
        }
        enableOther
        autofillField
        readOnlyAutofill
        previousQuestionIndexDependency
        __typename
      }
      deletedAt
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createTextMessage = /* GraphQL */ `
  mutation CreateTextMessage($input: TextMessageInput) {
    createTextMessage(input: $input) {
      items {
        id
        sender
        receiver
        message
        status
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const updateOrganizationalUnit = /* GraphQL */ `
  mutation UpdateOrganizationalUnit($input: OrganizationalUnitInput) {
    updateOrganizationalUnit(input: $input) {
      id
      facilityName
      organizationalUnit
      organizationalUnit_scan
      updatedAt
      deletedAt
      createdAt
      __typename
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient($input: PatientInput) {
    updatePatient(input: $input) {
      id
      idNumber
      idNumber_scan
      gender
      height
      yearOfBirth
      dateOfBirth
      idOrPassport
      phoneNumber
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      deviceSize
      deviceInfo
      changesInDevice
      caregiverName
      caregiverRelation
      participationConcluded
      assessmentScores {
        timestamp
        shoulderAbduction
        fingerExtension
        WTSeconds
        WTAssistantLevel
        __typename
      }
      numberOfTreatments
      recentTreatmentDate
      controlPatient
      treatmentType
      treatmentProtocolId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      phase
      stats
      deletedAt
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateTreatmentStage = /* GraphQL */ `
  mutation UpdateTreatmentStage($input: TreatmentStageInput) {
    updateTreatmentStage(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      exerciseCategories
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateExercisesCategory = /* GraphQL */ `
  mutation UpdateExercisesCategory($input: ExercisesCategoryInput) {
    updateExercisesCategory(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      exercises
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateExercise = /* GraphQL */ `
  mutation UpdateExercise($input: ExerciseInput) {
    updateExercise(input: $input) {
      id
      name
      name_scan
      name_filter
      idInApp
      tip
      description
      nameTranslations {
        code
        text
        __typename
      }
      tipTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      numOfRepetitions
      numOfSets
      secsInsteadOfReps
      illustrationFile
      progressionsList
      equipmentList
      useAssessmentScores
      shoulderAbduction
      fingerExtension
      assessmentScoresMatrix
      walkTest
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProgression = /* GraphQL */ `
  mutation UpdateProgression($input: ProgressionInput) {
    updateProgression(input: $input) {
      id
      name
      name_scan
      nameTranslations {
        code
        text
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment($input: EquipmentInput) {
    updateEquipment(input: $input) {
      id
      imageUrl
      name
      name_scan
      idInApp
      description
      nameTranslations {
        code
        text
        __typename
      }
      descriptionTranslations {
        code
        text
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTreatmentProtocol = /* GraphQL */ `
  mutation UpdateTreatmentProtocol($input: TreatmentProtocolInput) {
    updateTreatmentProtocol(input: $input) {
      id
      name
      name_scan
      phase
      nextPhaseMinSessions
      nextPhaseMinDays
      numberOfTreatments
      totalDaysLimit
      weeklySessionsLimit
      partialSessionsAllowed
      sessionsCounterType
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment($input: UpdateTreatmentInput) {
    updateTreatment(input: $input) {
      id
      patientId
      sessionId
      phase
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      status
      eventLog {
        timestamp
        status
        emfState
        waveformIndex
        stageId
        categoryId
        exerciseId
        __typename
      }
      notes
      treatmentId
      waveformIndex
      durations
      createdAt
      startedAt
      completedAt
      deletedAt
      zoomUserId
      zoomMeetingId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      categories {
        treatmentStageId
        exerciseCategoryId
        rating
        __typename
      }
      exercises {
        treatmentStageId
        exerciseCategoryId
        exerciseId
        rating
        status
        notes
        startedAt
        elapsed
        __typename
      }
      updateId
      updatedAt
      version
      worksheets {
        timestamp
        role
        userId
        treatmentEMFTime
        deviceEMFTime
        isTreatmentCounted
        operatorPresent
        answers {
          index
          title
          question
          meta
          answer
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: DeviceInput) {
    updateDevice(input: $input) {
      id
      serialNumber
      quickConnectNumber
      macAddress
      resistance
      deviceSize
      deletedAt
      updatedAt
      usage {
        timestamp
        tabletUserEmail
        userEmail
        patientIdNumber
        organizationalUnit
        __typename
      }
      createdAt
      __typename
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage($input: LanguageInput) {
    updateLanguage(input: $input) {
      id
      code
      name
      name_scan
      deletedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppVersion = /* GraphQL */ `
  mutation UpdateAppVersion($input: AppVersionInput) {
    updateAppVersion(input: $input) {
      id
      deviceId
      accountName
      appBuildNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDefaultDeviceForPatient = /* GraphQL */ `
  mutation UpdateDefaultDeviceForPatient($input: DefaultDeviceInput) {
    updateDefaultDeviceForPatient(input: $input) {
      result
      __typename
    }
  }
`;
export const updateQuestionnaire = /* GraphQL */ `
  mutation UpdateQuestionnaire($input: QuestionnaireInput) {
    updateQuestionnaire(input: $input) {
      id
      role
      phase
      name
      name_scan
      description
      isActive
      items {
        index
        required
        type
        title
        question
        questionTranslations {
          code
          text
          __typename
        }
        comment
        commentTranslations {
          code
          text
          __typename
        }
        commentIndex
        options
        optionsTranslations {
          code
          text
          __typename
        }
        enableOther
        autofillField
        readOnlyAutofill
        previousQuestionIndexDependency
        __typename
      }
      deletedAt
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateTextMessage = /* GraphQL */ `
  mutation UpdateTextMessage($input: TextMessageInput) {
    updateTextMessage(input: $input) {
      items {
        id
        sender
        receiver
        message
        status
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      startedAt
      total
      __typename
    }
  }
`;
export const startTreatment = /* GraphQL */ `
  mutation StartTreatment($input: ID) {
    startTreatment(input: $input) {
      id
      patientId
      sessionId
      phase
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      status
      eventLog {
        timestamp
        status
        emfState
        waveformIndex
        stageId
        categoryId
        exerciseId
        __typename
      }
      notes
      treatmentId
      waveformIndex
      durations
      createdAt
      startedAt
      completedAt
      deletedAt
      zoomUserId
      zoomMeetingId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
            __typename
          }
          __typename
        }
        __typename
      }
      categories {
        treatmentStageId
        exerciseCategoryId
        rating
        __typename
      }
      exercises {
        treatmentStageId
        exerciseCategoryId
        exerciseId
        rating
        status
        notes
        startedAt
        elapsed
        __typename
      }
      updateId
      updatedAt
      version
      worksheets {
        timestamp
        role
        userId
        treatmentEMFTime
        deviceEMFTime
        isTreatmentCounted
        operatorPresent
        answers {
          index
          title
          question
          meta
          answer
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const logDeviceUsage = /* GraphQL */ `
  mutation LogDeviceUsage($input: DeviceUsageInput) {
    logDeviceUsage(input: $input) {
      result
      __typename
    }
  }
`;
