/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    useInput
} from 'react-admin';
import { LinearProgress } from '@material-ui/core';

import { useFormContext, useWatch } from 'react-hook-form';
import { BQCheckbox, BQCheckboxInput, useCheckboxInputData } from './bq-form-components';
import * as deepcopy from 'deepcopy';
import { getTranslatedName, getValueByPath, isNullOrUndefined } from '../../utils/textUtils';

export const ExcerciseType = {
    DURING_TREATMENT: { type: 'DURING_TREATMENT', name: 'Exercises during treatment', durationInMinutes: 40, arrayName: 'treatmentExercises' },
    POST_TREATMENT: { type: 'POST_TREATMENT', name: 'Exercises after treatment', durationInMinutes: 20, arrayName: 'postTreatmentExercises' }
}

export const ExerciseParent = {
    TREATMENT_PROTOCOL: 'TREATMENT_PROTOCOL',
    PATIENT: 'PATIENT'
}

export const DataTypes = {
    TREATMENT_PROTOCOLS: 'TREATMENT_PROTOCOLS',
    TREATMENT_STAGES: 'TREATMENT_STAGES',
    TREATMENT_CATEGORIES: 'TREATMENT_CATEGORIES',
    EXERCISES: 'EXERCISES'
}

export const TreatmentProtocolExercisesData = props => {
    if (props.record && props.record.treatmentExercises && props.record.treatmentExercises.items && !props.record.postTreatmentExercises) {
        const hasTreatments = !!(props.record.treatmentExercises && props.record.treatmentExercises.items);
        props.record.postTreatmentExercises = {
            items: hasTreatments && props.record.treatmentExercises.items.filter(item => item.deletedAt === 0 && item.exerciseType === ExcerciseType.POST_TREATMENT.type) || []
        }
        props.record.treatmentExercises = {
            items: hasTreatments && props.record.treatmentExercises.items.filter(item => item.deletedAt === 0 && item.exerciseType === ExcerciseType.DURING_TREATMENT.type) || []
        }
    }
    return ({ ...props.children })
}

export const getLists = async (dataProvider, withTreatmentProtocols) => {
    const listsResults = await Promise.all([
        withTreatmentProtocols && dataProvider.getList('treatmentProtocols', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
            skipPaginationData: true
        }),
        dataProvider.getList('treatmentStages', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
            skipPaginationData: true
        }),
        dataProvider.getList('exercisesCategorys', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
            skipPaginationData: true
        }),
        dataProvider.getList('exercises', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
            skipPaginationData: true
        }),
        dataProvider.getList('progressions', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
            skipPaginationData: true
        })
    ])
    const treatmentProtocols = withTreatmentProtocols && listsResults[0].data || null
    const treatmentStages = listsResults[1].data
    const exercisesCategories = listsResults[2].data
    const exercises = listsResults[3].data
    const progressions = listsResults[4].data

    return {
        treatmentProtocolsCache: withTreatmentProtocols && Object.keys(treatmentProtocols).map(key => treatmentProtocols[key]) || null,
        treatmentStagesCache: Object.keys(treatmentStages).map(key => treatmentStages[key]),
        exercisesCategoriesCache: Object.keys(exercisesCategories).map(key => exercisesCategories[key]),
        exercisesCache: Object.keys(exercises).map(key => exercises[key]),
        progressionsCache: Object.keys(progressions).map(key => progressions[key])
    }
}

export const ExerciseCategoryCheckbox = props => {
    const formContext = useFormContext()
    const { cache, readOnly, forceEdit, langCode, source } = props
    const value = useCheckboxInputData({ ...props });
    const selectedExercisesKey = value && Object.keys(value).find(key => key.startsWith('selectedExercises'))
    const [data, setData] = React.useState()

    React.useEffect(() => {
        if (!isNullOrUndefined(value)) {
            const selectedExercisesArray = value[selectedExercisesKey] || []
            const localValue = !selectedExercisesArray?.some(c => !c.isEnabled)
            setData({
                value: localValue,
                indeterminate: selectedExercisesArray?.some(c => c.isEnabled) && selectedExercisesArray?.some(c => !c.isEnabled),
                selectedExercisesArray
            })
        }
    }, [value])

    const [checkboxValue, setCheckboxValue] = React.useState(data?.value)
    React.useEffect(() => {
        setCheckboxValue(data?.value)
    }, [data])


    return <BQCheckboxInput
        id={props.source}
        label={() => {
            const exerciseCategory = cache.exercisesCategoriesCache.find(category => category.id === value?.exerciseCategoryId)
            return getTranslatedName(exerciseCategory, langCode)
        }}
        readOnly={readOnly}
        value={checkboxValue}
        indeterminate={data?.indeterminate}
        variant="h6"
        onChange={(newValue) => {
            if (!readOnly || forceEdit) {
                data.selectedExercisesArray?.forEach((item, index) => {
                    formContext.setValue(`${source}.${selectedExercisesKey}[${index}].isEnabled`, !!newValue, { shouldTouch: true })
                });
            }
        }}
    />
}

export const TreatmentProtocolComponent = (props) => {
    const { isLoadingDictionaries, cache: tempCache } = props
    if (isLoadingDictionaries) {
        return <LinearProgress />
    }

    const cache = deepcopy(tempCache)

    const form = useFormContext();
    const values = form.getValues();

    const treatmentProtocolId = useWatch({
        name: 'treatmentProtocolId'
    })

    React.useEffect(() => {
        if (!isLoadingDictionaries && treatmentProtocolId) {
            handleTreatmentProtocolChange({
                cache,
                form,
                treatmentProtocolId
            })
        }
    }, [treatmentProtocolId, tempCache])

    const childrenWithProps = React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { record: props.record, form });
        }
        return child;
    });
    return childrenWithProps || <></>;
}

const handleTreatmentProtocolChange = ({ cache, form, treatmentProtocolId }) => {
    const formValues = form.getValues()
    const tempTreatmentProtocol = cache.treatmentProtocolsCache?.find(item => item.id === treatmentProtocolId)

    const patientSelections = formValues?.treatmentStages

    const currentTreatmentProtocol = {
        treatmentProtocolId,
        totalSessionTime: 0
    }
    form.setValue('currentTreatmentProtocol', {});
    form.resetField('currentTreatmentProtocol')
    const reconstructedTreatmentStages = tempTreatmentProtocol?.treatmentStages?.map((stage, index) => {
        const { treatmentStageId, exercisesCategories: protocolExercisesCategories } = stage || {};
        const treatmentStageFromCache = cache.treatmentStagesCache.find(item => item.id === treatmentStageId)
        if (treatmentStageFromCache) {
            treatmentStageFromCache.type = stage?.type
            treatmentStageFromCache.treatmentStageDuration = stage?.treatmentStageDuration
            treatmentStageFromCache.exerciseCategories = cache.exercisesCategoriesCache
                .filter(ec => treatmentStageFromCache.exerciseCategories.some(id => id === ec.id))
                .map(ec => {
                    const categoryFromProtocol = protocolExercisesCategories?.find(rcat => rcat.exerciseCategoryId === ec.id)
                    return {
                        index: categoryFromProtocol?.index || treatmentStageFromCache.exerciseCategories.indexOf(ec.id),
                        exerciseCategoryId: ec.id,
                        name: ec.name,
                        nameTranslations: ec.nameTranslations,
                        selectedExercises: ec.selectedExercises || ec.exercises && cache.exercisesCache
                            .filter(e => ec.exercises.some(id => id === e.id))
                            ?.sort((a, b) => ec.exercises.indexOf(a.id) - ec.exercises.indexOf(b.id))
                            .map(ex => {
                                const patientExerciseSelection = patientSelections
                                    ?.find(patientStage => patientStage.treatmentStageId === treatmentStageId)
                                    ?.exercisesCategories?.find(patientCategory => patientCategory.exerciseCategoryId === ec.id)
                                    ?.selectedExercisesIndexed?.find(patientExercise => patientExercise.exerciseId === ex.id)
                                const selectedExercises = categoryFromProtocol?.selectedExercises?.length && categoryFromProtocol?.selectedExercises || categoryFromProtocol?.selectedExercisesIndexed
                                const exerciseFromProtocol = selectedExercises?.find(rex => (rex.exerciseId || rex) === ex.id);
                                const availableProgressions = ex.progressionsList?.map(item => cache.progressionsCache.find(cp => cp.id == item))?.filter(item => item)

                                let isEnabled = (patientExerciseSelection?.isEnabled === null || patientExerciseSelection?.isEnabled === undefined)
                                    ? exerciseFromProtocol?.isEnabled
                                    : patientExerciseSelection.isEnabled

                                return {
                                    index: exerciseFromProtocol?.index,
                                    isEnabled,
                                    name: ex.name,
                                    nameTranslations: ex.nameTranslations,
                                    exerciseId: ex.id,
                                    secsInsteadOfReps: ex.secsInsteadOfReps,
                                    orgprogressionsList: ex.progressionsList,
                                    orgNumOfRepetitions: ex.numOfRepetitions,
                                    orgNumOfSets: ex.numOfSets,
                                    numOfRepetitions: patientExerciseSelection?.numOfRepetitions,
                                    numOfSets: patientExerciseSelection?.numOfSets,
                                    availableProgressions,
                                    progressionsList: patientExerciseSelection?.progressionsList
                                }
                            }).sort((a, b) => a.index - b.index)
                    }
                }).sort((a, b) => a.index - b.index) || []
        }
        return treatmentStageFromCache
    })?.sort((a, b) => a.index - b.index)

    reconstructedTreatmentStages?.forEach(item => {
        let currentDuration = 0;
        try {
            currentDuration = parseInt(item.treatmentStageDuration) || 0
        }
        catch {
            currentDuration = 0;
        }
        currentTreatmentProtocol.totalSessionTime += currentDuration
    })

    currentTreatmentProtocol.treatmentStages = reconstructedTreatmentStages

    form.setValue('currentTreatmentProtocol', currentTreatmentProtocol, { shouldTouch: false, shoudDirty: true });
}