import { useState } from "react";
import { useBQStyles } from "../../themes";
import { BQSvgIcon, createLabelFromSource } from "./bq-form-components";
import { BQIcons } from '../../symbols';
import { Typography } from "@material-ui/core";
import * as dayjs from 'dayjs'
import { getFromCache } from "../../utils/globals";

export const InputLayout = (props) => {
    const {
        id,
        label,
        style,
        source
    } = props
    const bqClasses = getFromCache('bqClasses');
    return <tr className={bqClasses.textInputContainer}>
        <td className={bqClasses.inputLabel}>
            <span className={`${id || source}_label`}>
                {label || createLabelFromSource(source)}
            </span>
        </td>
        <td className={bqClasses.textInput}>
            <span style={{ ...(style || {}), minWidth: '100%' }}>
                {props.children}
            </span>
        </td>
    </tr>
}

export const BQFilterDropDown = (props) => {
    const {
        id,
        items,
        onChange,
        onBlur,
        style,
        filterObject,
        source,
        defaultText = ''
    } = props

    const bqClasses = getFromCache('bqClasses');

    const [value, setValue] = useState(filterObject[source] || '')
    if (value !== filterObject[source]) {
        setValue(filterObject[source])
    }

    const inputComponent = <select
        id={`${id || source}_filter_input`}
        className={bqClasses.bqInputValue}
        value={value}
        onBlur={onBlur}
        onChange={(e) => {
            const select = e.target
            let value = select.options[select.selectedIndex].value || null
            setValue(value)
            filterObject[source] = value
            onChange && onChange(e)
        }}>
        <option value={''}>{defaultText}</option>
        {items?.map(item => <option value={item.value ?? item.id}>{item.name}</option>)}
    </select>

    return <InputLayout {...props}>
        {inputComponent}
    </InputLayout>
}

export const BQFilterInput = (props) => {
    const { id,
        maxLength,
        onChange,
        onBlur,
        type,
        style,
        placeholder,
        filterObject,
        setFilterObject,
        source,
        label
    } = props
    const bqClasses = getFromCache('bqClasses');


    let initialValue = null
    switch (source) {
        case 'from':
        case 'to':
            initialValue = (filterObject[source] && new Date(filterObject[source]))
            initialValue = initialValue && dayjs(initialValue).format('YYYY-MM-DDTHH:mm') || null
            break;
        default:
            initialValue = filterObject[source]
            break;
    }



    const [value, setValue] = useState(initialValue || '')
    if (value !== initialValue) {
        setValue(initialValue)
    }


    const inputComponent = <input id={`${id || source}_filter_input_${Math.random() * 1000}`}
        placeholder={placeholder}
        className={`${bqClasses.bqInputValue} ${id || source}_filter_input`}
        maxLength={maxLength}
        type={type}
        value={value}
        onBlur={(e) => {
            let v = e.target.value
            setValue(v)
            filterObject[source] = v
            setFilterObject && setFilterObject({ ...filterObject })
            onChange && onChange(v)
        }}
        onChange={(e) => {
            let v = e.target.value
            setValue(v)
            filterObject[source] = v
            setFilterObject && setFilterObject({ ...filterObject })
            onChange && onChange(v)
        }} />

    return <InputLayout {...props}>
        {inputComponent}
    </InputLayout>
}

export const BQFilterCheckbox = ({ field, label, value, onClick }) => {
    const classes = getFromCache('bqClasses')
    return <table onClick={() => onClick(!value)} className={classes.pointer}>
        <tr>
            <td className={classes.checkbox}>
                <div style={{ width: '16px', height: '16px' }} id={`${field}_checkbox`} value={value}>
                    <BQSvgIcon component={value ? BQIcons.checkbox_on : BQIcons.checkbox_off} />
                </div>
            </td>
            <td style={{ paddingTop: '12px' }}>
                {<Typography variant="caption" className={classes.index}
                    id={`${field}_label`}>{label || createLabelFromSource(field)}</Typography>}
            </td>
        </tr>
    </table>
}