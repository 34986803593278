import * as React from 'react';

export const cloneChild = (child, propsToCopy, readOnly) => {
    const childProps = child?.props || {}
    const readOnlyState = childProps?.readOnly !== undefined && childProps?.readOnly !== null ? childProps?.readOnly : readOnly

    if (React.isValidElement(child) && child.props) {
        const children = Array.isArray(child.props?.children) &&
            React.Children.map(child.props?.children, innerChild => {
                return cloneChild(innerChild, propsToCopy, readOnly)
            })
            ||
            child.props?.children
        const clonedChild = React.cloneElement(child, {
            ...propsToCopy,
            ...childProps,
            children,
            readOnly: readOnlyState
        });
        return clonedChild
    } else {
        return child
    }
}

export const getInitialPhase = (phases) => phases?.sort((a, b) => a.index - b.index)?.[0]