/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    useRecordContext,
} from 'react-admin';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { getDuplicates, validateDuplicates, validateNoSpaces, validateOU } from '../utils/validations';
import { BQInput } from './Generic/bq-input';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { prepareFormData } from '../utils/transforms';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { BQSimpleForm } from './Generic/bq-form-components';
import { bqAuthData } from '../utils/bq-auth';

const organizationTransform = async (data, setDuplicates) => {
    while (data?.organizationalUnit?.endsWith('.')) {
        data.name = data.organizationalUnit.substring(0, data.organizationalUnit.length - 1);
    }
    const duplicates = await getDuplicates('OrganizationalUnit', data, ['organizationalUnit']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }
    return prepareFormData(data)
};

const OrganizationList = () => (
    <BQModelList showDates nameField="organizationalUnit">
        <TextField source="organizationalUnit" label="Clinic ID" />
        <TextField source="facilityName" />
    </BQModelList>
);

const OrganizationEditor = (props) => {
    const { ou } = bqAuthData
    const initialOU = ou.match(/b[\W]{0,}r[\W]{0,}a[\W]{0,}i[\W]{0,}n[\W]{0,}q/ig)[0]
    const record = useRecordContext()
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A clinic',
        duplicates: props.duplicates
    }

    return <BQSimpleForm
        toolbar={<BQToolbar type="Clinic" nameField="organizationalUnit" />}
    >
        <BQSection title="Clinic Info" >
            <BQInput source="organizationalUnit" placeholder={`${initialOU}.SiteName`} label="Clinic ID"
                readOnly={!!record?.id}
                validate={[validateNoSpaces, validateOU]}
                duplicates={duplicatesCheckData}
            />
            <BQInput source="facilityName" />
        </BQSection>
    </BQSimpleForm>
}



const OrganizationMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => organizationTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <OrganizationEditor redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <OrganizationEditor redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const OrganizationCreate = (props) => OrganizationMutation(true, props)

const OrganizationEdit = (props) => OrganizationMutation(false, props)

export default BQCRUDBuilder({
    Create: OrganizationCreate,
    Edit: OrganizationEdit,
    List: OrganizationList
})

